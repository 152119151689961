import * as React from "react";
import { AcademicCapIcon } from "@heroicons/react/outline";

const Application = () => {
  return (
    <div className="max-w-screen-xl mx-auto py-12 lg:py-24">
      <div className="px-4">
        <h2 className="text-greenDark text-3xl text-center font-light font-quick tracking-tight">
          2022-2023 academic year application forms
        </h2>
        <div className="mt-10 lg:mt-14">
          <a
            href="/Website-Tuition-fee-2022-2023.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="max-w-sm mx-auto flex justify-center justify-items-center items-center p-5 border rounded-2xl text-greenDark hover:text-white hover:bg-greenDark border-greenDark space-x-4">
              <AcademicCapIcon
                className="h-[65px] w-[65px] text-green bg-[#DCE7E4] p-[20px] rounded-full"
                aria-hidden="true"
              />
              <h6 className="text-md font-bold font-quick text-[18px]">
                School Application Form
              </h6>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Application;
