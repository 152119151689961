import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "cta-bg.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <div>
      <div className="max-w-full mx-auto">
        <div className="relative">
          <div className="absolute inset-0">
            <GatsbyImage
              className="h-full w-full object-cover"
              image={data.file.childImageSharp.gatsbyImageData}
              alt="Background"
            />
            <div className="absolute inset-0 bg-[rgba(0,0,0,0.4)] mix-blend-multiply" />
          </div>
          <div className="max-w-screen-xl m-auto relative px-4 py-20 lg:py-40 text-center">
            <h1 className="font-quick font-bold text-white text-3xl sm:text-4xl lg:text-5xl mb-5">
              Admission
            </h1>
            <Link to="/" className="text-white font-light">
              Home
            </Link>
            <span className="text-white font-light px-2">/</span>
            <span className="text-white font-light">Admission</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
