import * as React from "react";
import Application from "../components/admission/application";
import Header from "../components/admission/header";
import Layout from "../components/layout";

const Admission = () => {
  return (
    <Layout pageTitle="Admission - Forest Grove Montessori Pre-School">
      <Header />
      <Application />
    </Layout>
  );
};

export default Admission;
